<template>
  <div :class="$isWeb ? 'mining-h5' : 'mining'">
    <div class="chart-container">
        <canvas id="chart" v-show="stats"></canvas>
        <div class="chart-empty" v-if="!stats">No miner is online</div>
    </div>
    <div class="content" v-if="stats">
        <TableColumn
          :columns="columns"
          :data = "data"
          :showBorder="true"
        />
    </div>
  </div>
</template>
<script>
import TableColumn from '@/components/layout/Table.vue'
import {getRigstats, getStats} from '../../libs/api.js'
import Chart from 'chart.js/auto'
const moment = require('moment')

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

let colorPlate = [
  '#005995', '#fa625f', '#600473', '#fbf579', '#08ffc8', '#204969', '#00dffc', '#008c9e',
  '#005f6b', '#343838', '#96ceb4', '#ffeead', '#d9534f', '#d9534f', '#ffad60', '#f3e8d6',
  '#a1bad0', '#d0a727', '#667572', '#17223b', '#263859', '#6b778d', '#ff6768'
]

let chartData = {
  type: 'line',
  data: {
  },
  options: {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Hashrate (g/s)'
        },
      }]
    }
  }
}

export default {
    data() {
        return {
            stats: null,
            rigStats: null,

            total_minute_15: null,
            total_hour_1: null,
            total_hour_6: null,
            total_hour_24: null,
            rigCount: 0,

            columns: [
                {
                    key: 'name',
                    title: 'Miner name',
                    type: 'text',
                    width: '180',
                    active: true
                },
                {
                    key: '15',
                    title: '15 minutes hashrate',
                    type: 'text',
                    width: '180'
                },
                {
                    key: '1',
                    title: '1 hour',
                    type: 'text',
                    width: '180'
                },
                {
                    key: '6',
                    title: '6 hours',
                    type: 'text'
                },
                {
                    key: '24',
                   title: '24hours',
                    type: 'text'
               }
            ],
            data: [
               {
                   name: '-',
                   '15': '-',
                   '1': '-',
                   '6': '-',
                   '24': '-'
               } 
            ]
        }
    },
    components: {
        TableColumn
    },
    methods: {
        createChart(chartId, chartData) {
            const ctx = document.getElementById(chartId);
            const { type, data, options } = chartData;
            new Chart(ctx, {
                type: type,
                data: data,
                options: options,
            });
        },
        movingAverage(array_, range_, digit_count){
            return array_.map((x, i)=>{
                let end = i + 1
                let start = end-range_
                if(start < 0)start = 0
                let array2 = array_.slice(start, end)
                return (this.sum(array2)/array2.length).toFixed(digit_count)
            })
        },
        sum(array_){
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            return array_.reduce(reducer)
        },
    },
    watch:{
        rigStats:function(val){
            let new_data = []
            for (let s of val){
                let d = {
                   name: s.rig,
                   '15': s.minute_15 + ' g/s',
                   '1': s.hour_1 + ' g/s',
                   '6': s.hour_6 + ' g/s',
                   '24': s.hour_24 + ' g/s',
                } 
                new_data.push(d)
            }
            this.data = new_data
        }
    },

    async created() {

        let resp = await getRigstats()
        console.log('rigstats:', resp.data)
        if(resp.data && resp.data.length > 0){
            this.rigStats = resp.data
            this.rigCount = resp.data.length
        for(let i = 0; i < resp.data.length; i++){
            this.total_minute_15 +=  this.rigStats[i].minute_15;
            this.total_hour_1 +=  this.rigStats[i].hour_1;
            this.total_hour_6 +=  this.rigStats[i].hour_6;
            this.total_hour_24 +=  this.rigStats[i].hour_24
        }
            this.total_minute_15 =  this.total_minute_15.toFixed(2)
            this.total_hour_1 =  this.total_hour_1.toFixed(2)
            this.total_hour_6 =  this.total_hour_6.toFixed(2)
            this.total_hour_24 =  this.total_hour_24.toFixed(2)
        }

        resp = await getStats('1hour')
        console.log(resp.data)
        if(resp.data){
            let data = resp.data
            let rigs = data.rigs
            let ats = data.ats
            let stats = data.data
            this.stats = stats
            chartData.data.labels = ats.map(at => {
                let m = moment(at * 1000)
                return m.format('HH:mm')
            })

            let datasets = []
            let colors = []
            for(let i=0; i<rigs.length; i++){
                let rig = rigs[i]
                let data = stats[rig]
                let color = colorPlate[getRandomInt(colorPlate.length)]
                if(colors.includes(color)){
                    color = colorPlate[getRandomInt(colorPlate.length)]
                }
                colors.push(color)
                datasets.push({
                    label: rig,
                    data: this.movingAverage(data, 15, 2),
                    borderColor: color,
                    fill: false
                })
            }
            chartData.data.datasets = datasets

            //if (this.isMobile()) {
            //    chartData.data = this.getMobileData(chartData.data)
            //}
            this.createChart('chart', chartData)
        }else{
            this.stats = null
        }
  },
}
</script>
<style lang="less" scoped>
    .mining{
        box-sizing: border-box;
        margin: 0 auto;
        margin-bottom: 8px; /*no*/
        padding: 64px 108px 84px; /*no*/
        width: 1120px; /*no*/
        background: #FFFFFF;
        box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15); /*no*/
        .chart-container{
            width: 100%;
            min-height: 472px; /*no*/
            background: rgba(0, 0, 0, 0);
            margin-bottom: 48px; /*no*/
            border: 1px solid #893EFF; /*no*/
            padding: 15px; /*no*/
        }
        .chart-empty{
            font-size: 30px; /*no*/
            color: #893EFF;
            text-align: center;
            margin-top: 100px; /*no*/
            font-weight: 600;
        }
    }
    .mining-h5{
        box-sizing: border-box;
        margin: 0 auto;
        margin-bottom: 8px;
        padding: 22px 60px 31px;
        width: 626px;
        background: #FFFFFF;
        box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);
        .chart-container{
            width: 100%;
            min-height: 190px;
            background: rgba(0, 0, 0, 0);
            margin-bottom: 48px;
            border: 1px solid #893EFF;
            padding: 15px;
        }
        .chart-empty{
            font-size: 30px;
            color: #893EFF;
            text-align: center;
            margin-top: 100px;
            font-weight: 600;
        }
    }
</style>