<template>
  <div :class="$isWeb ? 'card-h5' : 'card'">
    <div class="card-detail">
      <div class="card-item card-item-1">
        <div class="item">
          <p class="item-title">Total amount mined/Reward today</p>
          <p class="item-value">{{ total }}/{{ todayReward }} ツ</p>
        </div>
        <div class="item">
          <p class="item-title">Withdrawable</p>
          <p class="item-value">{{ available }} ツ</p>
        </div>
      </div>
      <div class="card-item card-item-2">
        <div class="item">
          <p class="item-title">Online miner in the last 1 hour</p>
          <p class="item-value">{{ rigsCount }}</p>
        </div>
        <div class="item">
          <p class="item-title">Total hash rate in the last 1 hour</p>
          <p class="item-value">{{ rate }} g/s</p>
        </div>
      </div>
    </div>
    <div class="title" @click="toWithdraw">
      <span class="avator">
        <img width="100%" src="../../assets/img/home/smile.png" />
      </span>
      <span class="name">Withdraw</span>
    </div>
  </div>
</template>
<script>
import { getSummary } from "../../libs/api.js";

export default {
  props: {},
  data() {
    return {
      rigsCount: "--",
      rate: "--",
      todayReward: "--",
    };
  },
  computed: {
    total() {
      return this.$store.state.total;
    },
    locked() {
      return this.$store.state.locked;
    },
    available() {
      return this.$store.state.available;
    },
    poolRate() {
      return this.$store.state.poolRate;
    },
    networkRate() {
      return this.$store.state.networkRate;
    },
    ratio() {
      return ((this.poolRate * 100) / this.networkRate).toFixed(1);
    },
  },

  methods: {
    async getSummary_() {
      let resp = await getSummary();
      if (resp.data) {
        this.rigsCount = resp.data.rig_count;
        this.todayReward = resp.data.today_reward;
        this.rate = resp.data.stats.total;

        if (resp.data.balance) {
          this.$store.commit("setBalance", resp.data.balance);
        }
      }
    },
    toWithdraw() {
      this.$router.push("/withdrawal");
    },
  },
  created() {
    this.getSummary_();
  },
};
</script>
<style lang="less" scoped>
.card {
  box-sizing: border-box;
  width: 1120px; /*no*/
  height: 315px; /*no*/
  background: #fff;
  box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.25); /*no*/
  margin: 0 auto;
  display: flex;
  .title {
    width: 170px; /*no*/
    display: flex;
    flex-direction: column;
    background: #893eff;
    justify-content: center;
    align-items: center;
    color: #fff;
    .avator {
      margin-bottom: 10px; /*no*/
    }
  }
  .card-detail {
    box-sizing: border-box;
    position: relative;
    width: 950px; /*no*/
    padding: 0 50px; /*no*/
    .card-item {
      display: flex;
      flex-direction: row;
    }
    .card-item-1 {
      margin-top: 58px; /*no*/
    }
    .card-item-2 {
      margin-top: 65px; /*no*/
    }
    .item {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
    .item-title {
      font-size: 22px; /*no*/
      line-height: 27px; /*no*/
      color: #888;
    }
    .item-value {
      margin-top: 17px; /*no*/
      font-size: 24px; /*no*/
      font-weight: bold;
      line-height: 29px; /*no*/
      color: #893eff;
    }
  }
}
.card-h5 {
  box-sizing: border-box;
  width: 626px;
  height: 337px;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.25);
  margin: 0 auto;
  display: flex;
  .title {
    width: 141px;
    display: flex;
    flex-direction: column;
    background: #893eff;
    justify-content: center;
    align-items: center;
    color: #fff;
    .avator {
      width: 30px;
      height: 30px;
      margin-bottom: 11px;
    }
    .name {
      font-size: 14px;
      font-weight: 600;
      // transform: scale(0.7)
    }
  }
  .card-detail {
    box-sizing: border-box;
    position: relative;
    width: 485px;
    padding: 0 50px;
    .card-item {
      display: flex;
      flex-direction: row;
    }
    .card-item-1 {
      margin-top: 43px;
    }
    .card-item-2 {
      margin-top: 50px;
    }
    .item {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .item-title {
      font-size: 22px;
      line-height: 27px;
      color: #888;
    }
    .item-value {
      margin-top: 17px;
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      color: #893eff;
    }
  }
}
</style>
