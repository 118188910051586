<template>
  <div :class="$isWeb ? 'reward-h5' : 'reward'">
    <div class="content">
        <TableColumn
          :columns="columns"
          :data = "data"
        />
        <div class="no-rewards" v-if="!rewards">No mining rewards recently</div>
        <br/>
    </div>
  </div>
</template>
<script>
import {getReward} from '../../libs/api.js'
const moment = require('moment')
import TableColumn from '@/components/layout/Table.vue'

export default {
    data() {
        return {
            
            rewards: null,
            tz: 'BJT',

            columns: [
                {
                    key: 'timestamp',
                    title: 'Date',
                    type: 'text',
                },
                {
                    key: 'count',
                    title: 'Miner Count',
                    type: 'text',
                },
                {
                    key: 'rate',
                    title: 'Hash',
                    type: 'text',
                },
                {
                    key: 'reward',
                    title: 'Your reward',
                    type: 'text'
                }

            ],

            data: null
        }
    },
    components: {
        TableColumn
    },
    methods: {
        async getRewards(tz=null){
            try{
                let resp
                if(tz){
                    resp = await getReward(tz)
                }else{
                    resp = await getReward(this.tz)
                }
                if(resp.data.length > 0){
                this.rewards = resp.data.map(r=>{
                    r.timestamp = moment(r.date).format('YYYY-MM-DD')
                    return r
                })}
            }catch(error){
                console.log(error)
            }
        },

        async getBlock(){
            try{
                let resp = await getBlocks()
                if(resp.data.length > 0){
                    this.blocks = resp.data.map(b=>{
                        b.timestamp = moment(b.dt).format('MM-DD HH:mm')
                        b.hash = this.shortHash(b.block_hash, 6)
                        b.value_ = (b.value/1000000000).toFixed(2)
                        return b
                    })
                }
            }catch(error){
                console.log(error)
            }
        }
    },
    created(){
        this.getRewards()
    },
    watch:{
        rewards:function(val){
            let new_data = []
            for (let r of val){
                let d = {
                   timestamp: r.timestamp,
                   count: r.rig_count,
                   rate: r.rate,
                   reward: r.reward + ' ツ',
                } 
                new_data.push(d)
            }
            this.data = new_data
        },
        blocks:function(val){
            let new_data = []
            for (let b of val){
                let h = this.grinBlockLink(b.height)
                let d = {
                   time: b.timestamp,
                   height: {href:h, value:b.height},
                   block_hash: {href:h, value:b.hash},
                   reward: b.value_ + ' ツ',
                   youReward: b.reward,
                } 
                new_data.push(d)
            }
            this.data = new_data
        }
    }
}
</script>
<style lang="less">
    .no-rewards{
            font-size: 20px; /*no*/
            color: #893EFF;
            text-align: center;
            margin-top: 50px; /*no*/
            margin-bottom: 50px; /*no*/
            font-weight: 500;
        }

    .reward{
        box-sizing: border-box;
        margin: 0 auto;
        margin-bottom: 8px;/*no*/
        padding: 50px 98px 0 108px;/*no*/
        width: 1120px;/*no*/
        // height: 800px;/*no*/
        // overflow: scroll;
        background: #FFFFFF;
        box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);/*no*/
        
        .desc{
            font-size: 16px;/*no*/
            font-weight: 400;
            line-height: 19px;/*no*/
            padding-top: 34px;/*no*/
            color: #888888;
            margin-bottom: 45px;/*no*/
        }
    }
    .reward-h5{
        box-sizing: border-box;
        margin: 0 auto;
        margin-bottom: 8px;
        padding: 27px 60px;
        width: 626px;
        background: #FFFFFF;
        box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);
        
        .desc{
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            padding-top: 34px;
            color: #888888;
            margin-bottom: 45px;
        }
    }
</style>