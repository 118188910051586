<template>
  <div class="home">
    <Banner :space="30" />
    <div :class="$isWeb ? 'home-container-h5' : 'home-container'">
       <div class="dashboard-container">
          <Dashboard />
       </div>
      <PageTitle title="Pool server"/>
      <Pool />
      <PageTitle title="Your miner status"/>
      <Mining />
      <PageTitle title="Grin blocks recently mined by GaeaPooL"/>
      <Recently />
      <PageTitle title="Your mining recent reward "/>
      <Reward />
      
      <el-dialog
        title="Bind Email"
        :visible.sync="dialogVisible"
        width="400px"
        :close-on-click-modal="false"
      >
        <p style="color: #893EFF; margin: 10px 0" v-if="error">
          Error: {{ error }}
        </p>
        <p style="color: #893EFF; margin: 10px 0" v-if="info">
          Info: {{ info }}
        </p>

        <div class="copy-container">
          <p style="color: #101010; margin: 20px 0">Your email:</p>
          <input class="input" style="width: 320px" v-model="email" />
        </div>
        <div class="copy-container">
          <p style="color: #101010; margin: 20px 0">verify code:</p>
          <input class="input" style="width: 320px" v-model="code" />
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" v-if="gotCode" disabled>
            Get verify code
          </el-button>
          
          <el-button v-else type="primary" @click="getCode">
            Get verify code
          </el-button>

          <el-button type="primary" @click="bindEmail">
            Bind Email
          </el-button>

        </span>
      </el-dialog>

    </div>
    <Footer />
  </div>
</template>

<script>
import Banner from '@/components/layout/Banner.vue'
import PageTitle from '@/components/layout/PageTitle.vue'
import Footer from '@/components/layout/Footer.vue'

import Card from '../Home/Card.vue'
import Calculator from '../Home/Calculator'
import Recently from '../Home/Recently'
import Reward from './Reward'

import Dashboard from './Dashboard'
import Mining from './Mining'
import Pool from './Pool'

import {
 getEmail,
 getCodeToBindEmail, 
 verifyCode
} from "../../libs/api.js";

export default {
  name: 'Home',
  
  data() {
    return {
      dialogVisible: false,
      email: null,
      code: null,
      error: '',
      info: '',
      gotCode: false,

    };
  },

  components: {
    Banner,
    Card,
    PageTitle,
    Calculator,
    Recently,
    Footer,
    Dashboard,
    Mining,
    Pool,
    Reward
  },

  methods: {
    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase())
    },

    checkForm(){
      this.error = ''
      if(this.email)this.email = this.email.trim()
      if (!this.email || !this.validateEmail(this.email)) {
        this.info = ''
        this.error = 'Wrong Email address.'
        return false
      }
      return true
    },

    checkForm2(){
      this.error = ''
      this.email = this.email.trim()
      if (!this.code) {
        this.error = 'no verify code'
        return false
      }
      return true
    },

    async getCode(){
      if(this.checkForm()){
        try{
          let resp = await getCodeToBindEmail(this.email)
          if(resp.data === 'ok' ){
            this.info = 'Verify code was sent to email. Input your code.'
            this.gotCode = true
          }else{
            this.error = 'Something is wrong. Try it later.'
          }
        }catch(error){
          if(error.response){
            let msg = error.response.data.message
            if(msg === 'post email too frequently'){
              this.error = 'Try it later.'
              return 
            }
          }
          this.error = 'Something is wrong. Try it later.'
        }
      }
    },
    closeModal(){
      this.dialogVisible = false
      this.email = null
      this.code = null
      this.error =  ''
      this.info = ''
    },
    async bindEmail(){
      if(this.checkForm2()){
         try{
          let resp = await verifyCode(this.code)
          if(resp.data === 'ok' ){
            this.info = 'Email is binded.'
            this.binded = true
            setTimeout(
              ()=> this.closeModal(), 
              3*1000
            )

          }else{
            this.error = 'Something is wrong. Try it later.'
          }
        }catch(error){
          if(error.response){
            let msg = error.response.data.message
            if(msg === 'code is wrong'){
              this.error = 'Verify code is wrong'
              return 
            }
          }
          this.error = 'Something is wrong. Try it later.'
        }
      }
    },
  },
  watch: {
    error:function(newVal){
      if(newVal){
        setTimeout(()=> this.error = '', 
        5*1000)
      }
    },
    info:function(newVal){
      if(newVal){
        setTimeout(()=> this.info = '', 
        5*1000)
      }
    },
  },
  async created(){
    let resp = await getEmail()
    if (!resp.data.email){
      this.dialogVisible = true
    }
  },

}

</script>
<style lang="less">
  .home{
      width: 100%;
  }
  .home-container{
      position: relative;
      padding-top: 145px; /*no*/
      min-width: 1120px; /*no*/
      .dashboard-container{
        position: absolute;
        top: -170px; /*no*/
        left: 0;
        right: 0;
        margin: 0 auto;
      }
  }
  .home-container-h5{
      position: relative;
      padding-top: 272px;
      .dashboard-container{
        position: absolute;
        top: -105px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
  }
</style>
